var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap"},[(!_vm.topMsgShow)?[_c('NavBar',{attrs:{"title":_vm.roomInfo.content || '',"left-arrow":""},on:{"click-left":function($event){return _vm.$router.go(-1)}}}),(_vm.topMsgInfo.content)?_c('NoticeBar',{attrs:{"color":"#1989fa","background":"#ecf9ff","left-icon":"volume-o"},on:{"click":function($event){_vm.topMsgShow = true}}},[_vm._v(" "+_vm._s(_vm.topMsgInfo.contentText)+" ")]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"loading_wrap"},[_c('Loading',{attrs:{"size":"20"}})],1):_vm._e(),_c('div',{ref:"list",staticClass:"list",style:({
        height: !_vm.topMsgInfo.content
          ? 'calc(100vh - 46px - 195rem / 16 / 2)'
          : 'calc(100vh - 46px - 195rem / 16 / 2 - 40px)',
      }),on:{"scroll":_vm.next}},[_vm._l((_vm.messages),function(item,index){return _c('div',{key:item.id || ("message_" + index),staticClass:"list_item",style:({
          marginTop:
            !index || (!!index && item.userid !== _vm.messages[index - 1].userid)
              ? ''
              : 'calc(36rem / 16 / 2)',
        })},[(
            !index || (!!index && item.userid !== _vm.messages[index - 1].userid)
          )?_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.$format(item.indate))+" ")]):_vm._e(),(item.userid != _vm.userInfo.id)?_c('div',{staticClass:"content_bar"},[_c('img',{staticClass:"avatar",attrs:{"src":item.userimg,"alt":""}}),_c('div',{staticClass:"right_bar"},[(
                !index ||
                (!!index && item.userid !== _vm.messages[index - 1].userid)
              )?_c('div',{staticClass:"nickname"},[_vm._v(" "+_vm._s(item.username)+": ")]):_vm._e(),_c('div',{staticClass:"msg_wrap"},[_c('div',{staticClass:"msg",class:((!index ||
                  (!!index && item.userid !== _vm.messages[index - 1].userid)
                    ? 'top16'
                    : '') + " " + (item.type == 'image' ? 'msg_image' : ''))},[(item.type == 'image')?_c('img',{staticClass:"images",attrs:{"src":_vm.verConfig.url + item.content,"alt":""},on:{"click":function($event){return _vm.preview(item.content)}}}):_c('span',{domProps:{"innerHTML":_vm._s(item.content)}})])])])]):_c('div',{staticClass:"content_bar ver_end"},[_c('div',{staticClass:"left_bar"},[_c('div',{staticClass:"msg_wrap"},[_c('div',{staticClass:"msg",class:item.type == 'image' ? 'msg_image' : ''},[(item.type == 'image')?_c('img',{staticClass:"images",attrs:{"src":_vm.verConfig.url + item.content,"alt":""},on:{"click":function($event){return _vm.preview(item.content)}}}):_c('div',{domProps:{"innerHTML":_vm._s(item.content)}})])])]),_c('img',{staticClass:"avatar",attrs:{"src":_vm.userInfo.userimg,"alt":""}})])])}),_c('div',{staticClass:"placeholder"})],2),_c('div',{staticClass:"footer"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.msgInput),expression:"msgInput"}],attrs:{"placeholder":_vm.$t('chat.room.msgPlaceholder')},domProps:{"value":(_vm.msgInput)},on:{"input":function($event){if($event.target.composing){ return; }_vm.msgInput=$event.target.value}}}),_c('div',{staticClass:"photo_wrap"},[_c('input',{staticClass:"file_input",attrs:{"type":"file"},on:{"input":_vm.upload}}),_c('Icon',{staticClass:"photo",attrs:{"name":"photo-o"}})],1),_c('button',{staticClass:"send",on:{"click":function($event){return _vm.send()}}},[_vm._v(_vm._s(_vm.$t("chat.room.send")))])])]:_vm._e(),_c('TopMsgDetails',{attrs:{"data":_vm.topMsgInfo},model:{value:(_vm.topMsgShow),callback:function ($$v) {_vm.topMsgShow=$$v},expression:"topMsgShow"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }