<template>
  <div class="top_details_wrap" v-if="show">
    <NavBar :title="$t('chat.list.nav')" left-arrow @click-left="handleBack" />
    <div class="content_bar">
      <div class="userInfo">
        <div class="time">
          <span>{{ $t("chat.room.sender") }}:&nbsp;</span>{{ $format(data.indate) }}
        </div>
        <div class="nickname">
          <span>{{ $t("chat.room.sendTime") }}:&nbsp;</span>{{ data.username }}
        </div>
      </div>
      <h3 class="content_title">{{ $t("chat.room.content") }}:</h3>
      <div class="top_details_content" v-html="data.content"></div>
      <div class="height20"></div>
    </div>
  </div>
</template>

<script>
import { NavBar } from "vant";
export default {
  components: { NavBar },
  props: {
    data: {
      type: Object,
      default: () => ({}),
      require: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "show",
    event: "updateShow",
  },
  methods: {
    handleBack() {
      this.$emit("updateShow", false);
    },
  },
};
</script>

<style lang="less" scoped>
.content_bar {
  height: calc(100vh - 46px);
  overflow-y: auto;
}
.userInfo {
  padding: calc(10rem / 16);
  .nickname {
    font-size: calc(14rem / 16);
    color: #999;
    margin-top: 8px;
    > span {
      color: #333;
    }
  }
  .time {
    font-size: calc(14rem / 16);
    color: #999;
    > span {
      color: #333;
    }
  }
}
.content_title {
  padding: 0 calc(10rem / 16);
  padding-top: calc(10rem / 16);
  margin-bottom: 0;
}
.top_details_content {
  padding: calc(10rem / 16);
  overflow: auto;
  // height: calc(100vh - 46px - 20px - 120px);
}
.height20 {
  height: 20px;
}
</style>