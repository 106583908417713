import { render, staticRenderFns } from "./topMsgDetails.vue?vue&type=template&id=664162fc&scoped=true&"
import script from "./topMsgDetails.vue?vue&type=script&lang=js&"
export * from "./topMsgDetails.vue?vue&type=script&lang=js&"
import style0 from "./topMsgDetails.vue?vue&type=style&index=0&id=664162fc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "664162fc",
  null
  
)

export default component.exports