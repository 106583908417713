<template>
  <div class="wrap">
    <template v-if="!topMsgShow">
      <NavBar
        :title="roomInfo.content || ''"
        left-arrow
        @click-left="$router.go(-1)"
      />
      <NoticeBar
        color="#1989fa"
        background="#ecf9ff"
        left-icon="volume-o"
        v-if="topMsgInfo.content"
        @click="topMsgShow = true"
      >
        <!-- <div v-html="topMsgInfo.content"></div> -->
        {{ topMsgInfo.contentText }}
      </NoticeBar>
      <div class="loading_wrap" v-if="loading">
        <Loading size="20" />
      </div>
      <div
        class="list"
        ref="list"
        @scroll="next"
        :style="{
          height: !topMsgInfo.content
            ? 'calc(100vh - 46px - 195rem / 16 / 2)'
            : 'calc(100vh - 46px - 195rem / 16 / 2 - 40px)',
        }"
      >
        <div
          class="list_item"
          v-for="(item, index) in messages"
          :key="item.id || `message_${index}`"
          :style="{
            marginTop:
              !index || (!!index && item.userid !== messages[index - 1].userid)
                ? ''
                : 'calc(36rem / 16 / 2)',
          }"
        >
          <div
            class="time"
            v-if="
              !index || (!!index && item.userid !== messages[index - 1].userid)
            "
          >
            {{ $format(item.indate) }}
          </div>
          <div class="content_bar" v-if="item.userid != userInfo.id">
            <img :src="item.userimg" alt="" class="avatar" />
            <div class="right_bar">
              <div
                class="nickname"
                v-if="
                  !index ||
                  (!!index && item.userid !== messages[index - 1].userid)
                "
              >
                {{ item.username }}:
              </div>
              <div class="msg_wrap">
                <!-- <div class="left"></div> -->
                <div
                  class="msg"
                  :class="`${
                    !index ||
                    (!!index && item.userid !== messages[index - 1].userid)
                      ? 'top16'
                      : ''
                  } ${item.type == 'image' ? 'msg_image' : ''}`"
                >
                  <img
                    :src="verConfig.url + item.content"
                    alt=""
                    v-if="item.type == 'image'"
                    class="images"
                    @click="preview(item.content)"
                  />
                  <span v-else v-html="item.content"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="content_bar ver_end" v-else>
            <div class="left_bar">
              <div class="msg_wrap">
                <!-- <div class="left"></div> -->
                <div
                  class="msg"
                  :class="item.type == 'image' ? 'msg_image' : ''"
                >
                  <img
                    :src="verConfig.url + item.content"
                    alt=""
                    v-if="item.type == 'image'"
                    class="images"
                    @click="preview(item.content)"
                  />
                  <div v-else v-html="item.content"></div>
                </div>
              </div>
            </div>
            <img :src="userInfo.userimg" alt="" class="avatar" />
          </div>
        </div>
        <div class="placeholder"></div>
      </div>
      <div class="footer">
        <!-- <input type="text" v-model="msgInput" :placeholder="$t('chat.room.msgPlaceholder')" /> -->
        <textarea
          v-model="msgInput"
          :placeholder="$t('chat.room.msgPlaceholder')"
        ></textarea>
        <div class="photo_wrap">
          <input type="file" class="file_input" @input="upload" />
          <Icon name="photo-o" class="photo" />
        </div>
        <button class="send" @click="send()">{{ $t("chat.room.send") }}</button>
      </div>
    </template>
    <TopMsgDetails :data="topMsgInfo" v-model="topMsgShow" />
  </div>
</template>

<script>
import { $get, $post, $upload } from "@/utils/request.js";
import TopMsgDetails from "./topMsgDetails.vue";
import {
  NavBar,
  PullRefresh,
  List,
  Icon,
  Toast,
  ImagePreview,
  Loading,
  NoticeBar,
} from "vant";
import TimeCheck from "@/utils/timeSynchronization.js";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default {
  components: {
    NavBar,
    PullRefresh,
    List,
    Icon,
    Loading,
    NoticeBar,
    Editor,
    TopMsgDetails,
  },
  data() {
    return {
      topMsgShow: false,
      roomInfo: {},
      messages: [],
      interval: null,
      loading: false,
      userInfo: {},
      msgInput: "",
      sendLoading: false,
      page: 1,
      hasMore: true,
      newIndex: "",
      oldIndex: "",
      getMsgLoading: false,
      topMsgInfo: {},
    };
  },
  created() {
    this.roomInfo.id = this.$route.query.id;
    this.getRoomInfo();
    this.getMessages();
    this.getTopmessage();
    !this.interval && (this.interval = new TimeCheck({ time: new Date() }));
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
  },
  methods: {
    async getRoomInfo() {
      try {
        const res = await $get("/find/lists", { id: this.roomInfo.id });
        const { ret, msg, data } = res.data;
        if (ret == 1) {
          const { hasMorePages, lists } = data;
          this.roomInfo = lists[0];
        }
      } catch (error) {
        console.log(error);
      }
    },
    setStorage(index) {
      let storage = localStorage.getItem("seen");
      if (!!storage) {
        storage = JSON.parse(storage);
      } else {
        storage = {};
      }
      storage[this.roomInfo.id] = index;
      localStorage.setItem("seen", JSON.stringify(storage));
    },
    toListBottom() {
      this.$nextTick(() => {
        const height = this.$refs.list.scrollHeight;
        this.$refs.list.scrollTo(0, height);
      });
    },
    async getMessages(params = {}) {
      this.getMsgLoading = true;
      try {
        const res = await $get("/find/comment", {
          id: this.roomInfo.id,
          ...params,
        });
        const { ret, msg, data } = res.data;
        this.getMsgLoading = false;
        this.loading = false;
        if (ret == 1) {
          const { hasMorePages, lists } = data;
          // console.log(lists);
          if (!params.page && !params.index) {
            this.hasMore = hasMorePages;
            this.newIndex = lists[0].index;
            this.oldIndex = lists[0].index;
            this.setStorage(this.newIndex);
            this.messages = lists.reverse();
            this.toListBottom();
            this.interval.sleep(() => {
              if (!this.getMsgLoading) {
                this.getMessages({ index: this.newIndex });
              }
            }, 3);
            return;
          }
          if (params.page) {
            this.hasMore = hasMorePages;
            const oldHeight = this.$refs.list.scrollHeight;
            this.messages = [...lists.reverse(), ...this.messages];
            this.$nextTick(() => {
              const newHeight = this.$refs.list.scrollHeight;
              this.$refs.list.scrollTo(0, newHeight - oldHeight);
            });
            return;
          }
          if (params.index) {
            if (!lists.length) return;
            this.newIndex = lists[0].index;
            this.setStorage(this.newIndex);
            this.messages = [...this.messages, ...lists.reverse()];
            this.toListBottom();
          }
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.getMsgLoading = false;
      }
    },
    next() {
      const top = this.$refs.list.scrollTop;
      if (top == 0 && this.hasMore && this.loading == false) {
        this.loading = true;
        this.page++;
        this.getMessages({
          page: this.page,
          index: this.oldIndex,
        });
      }
    },
    async send(file = "") {
      if (this.sendLoading) return;
      Toast.loading({ duration: 0 });
      this.sendLoading = true;
      try {
        console.log(">>>", this.msgInput);
        const res = await $post("/find/comment/add", {
          id: this.roomInfo.id,
          content: !!file ? file : this.msgInput,
          type: !!file ? "image" : "text",
        });
        const { ret, msg, data } = res.data;
        Toast.clear();
        this.sendLoading = false;
        this.msgInput = "";
        if (ret == 1) {
          const { comment_new } = data;
          // this.messages.push(comment_new[0]);
          // this.$nextTick(() => {
          //   const height = this.$refs.list.scrollHeight;
          //   this.$refs.list.scrollTo(0, height);
          // });
          return;
        }
        Toast(msg);
      } catch (error) {
        console.log(error);
        Toast.clear();
        this.sendLoading = false;
      }
    },
    async upload(e) {
      Toast.loading({ duration: 0 });
      const file = e.target.files[0];
      const params = new FormData();
      params.append("image", file);
      try {
        const { ret, data, msg } = await $upload({
          url: "/users/upload",
          data: params,
        });
        this.send(data.path);
      } catch (error) {
        console.log(error);
        Toast.clear();
      }
    },
    preview(url) {
      ImagePreview([this.verConfig.url + url]);
    },
    async getTopmessage() {
      try {
        const res = await $get("/find/comment", {
          id: this.roomInfo.id,
          istop: 1,
        });
        const { ret, msg, data } = res.data;
        if (ret == 1) {
          const { hasMorePages, lists } = data;
          this.topMsgInfo = lists[0] || {};
          const msgDiv = document.createElement("div");
          msgDiv.innerHTML = this.topMsgInfo.content;
          this.topMsgInfo.contentText = msgDiv.innerText;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  destroyed() {
    this.interval.clear();
  },
};
</script>

<style lang="less" scoped>
.wrap {
  height: 100vh;
  background: #f4f5f8;
  overflow: hidden;
}

.loading_wrap {
  display: flex;
  justify-content: center;
  padding-top: calc(12rem / 16 / 2);
  padding-bottom: calc(8rem / 16 / 2);
}

.list {
  .placeholder {
    height: calc(40rem / 16 / 2);
  }

  padding: 0 calc(32rem / 16 / 2) 0;
  // margin-bottom: calc(800rem / 16 / 2);
  padding-top: calc(25rem / 16 / 2);
  height: calc(100vh - 46px - 195rem / 16 / 2 - 40px);
  overflow-y: auto;

  .list_item {
    margin-top: calc(56rem / 16 / 2);

    .time {
      font-size: calc(20rem / 16 / 2);
      line-height: calc(20rem / 16 / 2);
      color: #010101;
      text-align: center;
      margin-bottom: calc(26rem / 16 / 2);
    }

    .ver_end {
      // align-items: flex-end;
    }

    .content_bar {
      display: flex;

      .avatar {
        width: calc(90rem / 16 / 2);
        height: calc(90rem / 16 / 2);
        border-radius: 8px;
      }

      .top16 {
        margin-top: calc(16rem / 16 / 2);
      }

      .right_bar {
        margin-left: calc(20rem / 16 / 2);

        .nickname {
          font-size: calc(24rem / 16 / 2);
          color: #8e8e8e;
          margin-left: calc(15rem / 16 / 2);
        }

        .msg_wrap {
          position: relative;

          .left {
            // position: absolute;
            // width: calc(22rem / 16);
            // height: calc(15rem / 16);
            // left: calc(-10rem / 16);
            // top: 0;
            // background-image: url("../../assets/icon/chatroom/bubble.png");
            // background-position: left top;
          }

          .msg {
            // background-image: url("../../assets/icon/chatroom/bubble.png");
            background: #fff;
            border-radius: calc(10rem / 16);
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
            padding: calc(34rem / 16 / 2);
            padding-left: calc(37rem / 16 / 2);
            background-position: top left;
            background-size: 100% 100%;
            min-width: calc(276rem / 16 / 2);
            max-width: calc(386rem / 16 / 2);
            word-break: break-word;

            .images {
              max-width: calc(386rem / 16 / 2);
              width: calc(200rem / 16 / 2);
              min-height: calc(20rem / 16);
            }
          }

          .msg_image {
            display: flex;
            background: none;
            padding: 0;
            padding-left: calc(10rem / 16 / 2);
          }
        }
      }

      .left_bar {
        .right_bar();
        justify-content: flex-end;
        width: 100%;
        margin-right: calc(24rem / 16 / 2);
        margin-left: 0;

        .msg_wrap {
          display: flex;
          justify-content: flex-end;

          .msg {
            margin-top: 0;
            // background-image: url("../../assets/icon/chatroom/bubble_mine.png");
            box-shadow: none;
            background: linear-gradient(to left, #9275e7, #747fe6);
            color: #fff;
            padding: calc(25rem / 16 / 2);
            padding-left: calc(25rem / 16 / 2);
            padding-right: calc(37rem / 16 / 2);
          }

          .msg_image {
            background: none;
            padding: 0;
            padding-right: calc(10rem / 16 / 2);
            justify-content: flex-end;
          }
        }
      }
    }
  }
}

.footer {
  padding: calc(10rem / 16) calc(30rem / 16 / 2) calc(30rem / 16 / 2);
  background: #f4f5f8;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: calc(100vw - (30rem / 16));

  > input {
    flex-grow: 1;
    padding: calc(20rem / 16 / 2);
    border-radius: 10px;
    border: 4px solid #6385e6;
  }

  > textarea {
    flex-grow: 1;
    padding: calc(20rem / 16 / 2);
    height: calc(18rem / 16);
    border-radius: 10px;
    border: 4px solid #6385e6;
  }

  .editor {
    flex-grow: 1;
    // height: calc(300rem / 16);
    padding: calc(20rem / 16 / 2);
    border-radius: 10px;
    border: 4px solid #6385e6;

    ::v-deep .w-e-scroll {
      border: none !important;

      div {
        border: none !important;
      }
    }

    ::v-deep #wangEditor-1 {
      border: none !important;
    }
  }

  ::v-deep .tox-tinymce {
    flex-grow: 1;
    padding: calc(20rem / 16 / 2);
    border-radius: 10px;
    border: 4px solid #6385e6;
    height: calc(30rem / 16);

    #tinymce {
      margin: 0 !important;
    }
  }

  .photo_wrap {
    position: relative;

    .file_input {
      margin-left: calc(25rem / 16 / 2);
      width: calc(60rem / 16 / 2);
      height: calc(60rem / 16 / 2);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
      opacity: 0;
    }

    .photo {
      margin-left: calc(25rem / 16 / 2);
      font-size: calc(60rem / 16 / 2);
      color: #a4afcf;
    }
  }

  .send {
    margin-left: calc(25rem / 16 / 2);
    color: #fff;
    background: #847ae7;
    border: none;
    padding: calc(6rem / 16) calc(10rem / 16);
    border-radius: 8px;

    &:active {
      opacity: 0.8;
    }
  }
}
</style>